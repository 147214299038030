<template>
  <span class="currency-value" :class="{ large: isLarge, xlarge: isXLarge }">
    <span class="currency" v-if="currency">{{ currency }} </span>
    {{ commaNumber(integer, "'")
    }}<span class="decimal">
      {{ fractional ? `.${fractional}` : forceFractional ? "." + "0".repeat(decimal) : null
      }}<sup v-if="percentage">%</sup>
    </span>
  </span>
</template>

<script>
import commaNumber from "comma-number";

export default {
  name: "CurrencyValue",
  props: {
    currency: String,
    size: {
      type: String,
      default: "medium",
    },
    forceFractional: Boolean,
    percentage: Boolean,
    decimal: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      isLarge: this.size === "large",
      isXLarge: this.size === "xlarge",
      integer: 0,
      fractional: 0,
    };
  },
  mounted() {
    this.parseValueFromSlot();
  },
  updated() {
    this.parseValueFromSlot();
  },
  methods: {
    commaNumber,
    parseValueFromSlot() {
      const slot = this.$slots.default()[0];
      [this.integer, this.fractional] = String(Number(slot.children).toFixed(this.decimal)).split(
        ".",
      );
    },
  },
};
</script>
<style scoped lang="scss">
.currency-value {
  display: flex;
  line-height: 1;
  align-items: end;

  &.large {
    font-size: 1.36em;

    span.currency {
      font-weight: 600;
    }
  }

  &.xlarge {
    font-size: 1.6em;
  }

  .currency {
    align-self: flex-start;
    margin-top: 0.1em;
    margin-right: 0.2em;
    font-size: 1em;
    font-weight: 600;
    opacity: 0.7;
  }

  .decimal {
    opacity: 1;
  }
}
</style>
